.loading-container {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.2);
}
.loading-container img {
    position: absolute;
    top: 45%;
    width: 100px;
    height: 100px;
}
